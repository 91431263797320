import { useCallback, useState } from "react";
import { motion } from "framer-motion";

const Pregame = ({
  connect,
}: {
  connect: (code: number, name: string) => Promise<unknown>;
}) => {
  const [code, setCode] = useState("");
  const [enteredCode, setEnteredCode] = useState(false);
  const [name, setName] = useState("");
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");

  const enterCode = useCallback(async (code: number) => {
    try {
      setLoading(true);
      const res = await fetch(
        "https://play.picturedate.live/status?code=" + code
      );
      const success = (await res.text()) === "true";
      if (!success)
        setError("Oops! We couldn't find a game matching this PIN.");
      return success;
    } catch (e) {
      console.error(e);
      setError("Failed to connect to server. Try again later?");
      return false;
    } finally {
      setLoading(false);
    }
  }, []);

  const joinGame = useCallback(
    async (code: number, name: string) => {
      try {
        setLoading(true);
        const res = await connect(code, name);
        if (res !== "Connected") {
          setError((res as Error).message);
          return;
        }
      } catch (e) {
        console.error(e);
        setError("Failed to connect to server. Try again later?");
        return;
      } finally {
        setLoading(false);
      }
    },
    [connect]
  );
  return (
    <motion.main className="h-full flex justify-center items-center relative">
      <img
        src="/bgimageblue.png"
        alt=""
        className="absolute top-0 left-0 w-full h-full -z-10 object-cover"
      />
      <div className="bg-white rounded-xl p-5 relative overflow-hidden flex flex-col items-center w-[290px]">
        <h1 className="mb-[30px] text-center text-3xl font-bold">
          PictureDate
        </h1>
        <input
          value={enteredCode ? name : code}
          onChange={(e) =>
            e.target.value.length > (enteredCode ? 16 : 6)
              ? null
              : enteredCode
              ? setName(e.target.value)
              : setCode(e.target.value)
          }
          type={enteredCode ? "text" : "number"}
          className="p-3 text-[18px] h-14 mb-5 rounded-md"
          placeholder={enteredCode ? "Enter nickname" : "Enter PIN"}
        />
        {error.length > 0 && (
          <h1 className="bg-red-400 max-w-[250px] text-center text-black rounded-xl p-2 text-lg font-medium">
            {error}
          </h1>
        )}
        <br />
        <button
          onClick={async () => {
            setError("");
            if (enteredCode) {
              joinGame(Number(code), name);
            } else {
              setEnteredCode(await enterCode(Number(code)));
            }
          }}
          className="text-white bg-black border-2 border-solid border-[#1A1A1A] rounded-xl cursor-pointer text-lg font-medium outline-none px-6 py-4 text-center duration-300 ease-[cubic-bezier(.23, 1, 0.32, 1)] w-full tracking-[0.3ch] hover:shadow-lg hover:-translate-y-[2px]"
        >
          {enteredCode ? "Join Game" : "Connect"}
        </button>
        {loading && (
          <div className="absolute top-0 left-0 w-full h-full bg-black/60 flex items-center justify-center scale-150 animate-fade">
            <img
              src="/loading.gif"
              alt="Loading"
              className="w-10 h-10 animate-[spin_500ms_linear_infinite]"
            />
          </div>
        )}
      </div>
      {/* <div className={styles.footer}>
        Host your own PictureDate game{" "}
        <a href="#" id={styles.footerLink}>
          here
        </a>
      </div> */}
    </motion.main>
  );
};

export default Pregame;
