import React from "react";
import InfoBar from "./InfoBar";
import { motion } from "framer-motion";
import { GameState } from "./types";

const MainScreen = ({ state }: { state: GameState }) => {
  return (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      className="w-full h-full flex flex-col items-center"
    >
      <div className="flex-1 w-full flex justify-center items-center px-4 overflow-hidden">
        {state === "starting" ? (
          <motion.div
            animate={{ x: 0 }}
            initial={{ x: "100vw" }}
            exit={{ x: "-100vw" }}
            transition={{ delay: 1 }}
            key="starting"
          >
            <h1 className="text-4xl font-bold mb-4 text-sky-400">
              The game is starting!
              <br />
              Are you ready?
            </h1>
          </motion.div>
        ) : null}
      </div>
      <InfoBar />
    </motion.div>
  );
};

export default MainScreen;
