import React, {
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState,
} from "react";
import { motion } from "framer-motion";
import Character from "./Character";
import Separator from "./Separator";
import { AppContextType, ICONS } from "./types";
import { AppContext } from "./App";

/* 

const capturePhoto = () => {
  const canvas = document.createElement('canvas');
  canvas.width = videoRef.current.videoWidth;
  canvas.height = videoRef.current.videoHeight;
  const ctx = canvas.getContext('2d');
  ctx.drawImage(videoRef.current, 0, 0, canvas.width, canvas.height);

  // Convert canvas to data URL
  const imageDataUrl = canvas.toDataURL('image/png');

  // Optionally convert Data URL to Blob if needed for uploading
  fetch(imageDataUrl)
    .then(res => res.blob())
    .then(blob => {
      // Upload blob...
    });
};
*/

const CustomizeCharacter = ({ exit }: { exit: any }) => {
  const { player, socket, settings } = useContext(AppContext) as AppContextType;
  const [stream, setStream] = useState<MediaStream | null>(null);
  const video = useRef<HTMLVideoElement>(null);

  const selectIcon = useCallback(
    (icon: number) => {
      socket.emit("icon", icon);
    },
    [socket]
  );

  useEffect(() => {
    if (stream) {
      video.current!.srcObject = stream;
      return function cleanup() {
        stream.getTracks().forEach((track) => {
          track.stop();
        });
      };
    }
  }, [stream]);

  const startStream = useCallback(async () => {
    try {
      const stream = await navigator.mediaDevices.getUserMedia({
        video: {
          facingMode: "user",
        },
      });
      setStream(stream);
    } catch (err) {
      console.error(err);
      alert(
        "Failed to access camera. Please check your permissions and try again."
      );
    }
  }, []);

  const capturePhoto = useCallback(() => {
    if (!video.current) return;
    const canvas = document.createElement("canvas");
    canvas.width = video.current.videoWidth;
    canvas.height = video.current.videoHeight;
    console.log(canvas.width, canvas.height);
    const ctx = canvas.getContext("2d");
    ctx!.drawImage(video.current, 0, 0, canvas.width, canvas.height);

    // Convert canvas to data URL
    const imageDataUrl = canvas.toDataURL("image/png");

    socket.emit("customPhoto", imageDataUrl);
    setStream(null);

    // Optionally convert Data URL to Blob if needed for uploading
    // fetch(imageDataUrl)
    //   .then(res => res.blob())
    //   .then(blob => {
    //     // Upload blob...
    //   });
  }, [socket]);

  return (
    <motion.div
      className="fixed left-0 top-0 w-full h-full flex items-center justify-center"
      initial={{ backgroundColor: "rgba(0, 0, 0, 0)" }}
      animate={{ backgroundColor: "rgba(0, 0, 0, 0.4)" }}
      exit={{ backgroundColor: "rgba(0, 0, 0, 0)" }}
    >
      <motion.div
        className="w-[400px] h-[750px] max-w-[95%] max-h-[95%] rounded-xl bg-white flex flex-col items-center py-2 px-5 overflow-hidden"
        initial={{ height: 0 }}
        animate={{ height: 750 }}
        exit={{ height: 0 }}
        transition={{ ease: "easeInOut" }}
      >
        <Character />
        {stream ? (
          <>
            <div className="w-full aspect-square rounded-xl shadow-xl mt-4 overflow-hidden relative">
              <video
                autoPlay
                playsInline
                ref={video}
                className="object-cover w-full h-full flip"
              ></video>
              <div className="absolute left-0 top-0 w-full h-full flex justify-center items-center">
                <div className="w-[50%] rounded-full aspect-[3/4.2] border-white border-dashed border-4"></div>
              </div>
            </div>

            <div
              className="material-symbols-outlined bg-sky-400 p-5 text-6xl rounded-full shadow shadow-black/30 mt-4 cursor-pointer"
              onClick={capturePhoto}
            >
              photo_camera
            </div>
            <div className="flex-1"></div>
          </>
        ) : (
          <>
            {settings && settings.avatars === "true" && (
              <>
                <div
                  className="flex items-center w-full justify-between mt-8 bg-sky-400 px-5 py-2 rounded-xl shadow-xl mb-8 cursor-pointer"
                  onClick={startStream}
                >
                  <h1 className="text-3xl font-bold">Take a selfie</h1>
                  <div className="material-symbols-outlined bg-sky-400 p-5 text-6xl rounded-full shadow shadow-black/30">
                    photo_camera
                  </div>
                </div>
                <Separator text="or" />
              </>
            )}

            <div className="mt-8 w-full flex-1 overflow-y-auto flex justify-center flex-wrap">
              {ICONS.map((icon, i) => (
                <img
                  alt="icon"
                  src={icon}
                  key={i}
                  onClick={() => selectIcon(i)}
                  className={`w-20 h-20  p-2 rounded-full m-[2px] cursor-pointer duration-300 ${
                    player.icon === i.toString() ? "bg-sky-400" : "bg-sky-100"
                  }`}
                />
              ))}
            </div>
          </>
        )}

        <div
          className="bg-sky-400 px-5 py-2 rounded-md mt-2 text-2xl shadow-md font-bold cursor-pointer"
          onClick={exit}
        >
          Finish
        </div>
      </motion.div>
    </motion.div>
  );
};

export default CustomizeCharacter;
