import React, { useCallback, useRef, useState } from "react";
import Pregame from "./Pregame";
import { Socket, io } from "socket.io-client";
import Game from "./Game";
import { AppContextType, ICONS, Player } from "./types";

export function sleep(ms: number) {
  return new Promise((resolve) => setTimeout(resolve, ms));
}

export const AppContext = React.createContext<AppContextType | undefined>(
  undefined
);

const App = () => {
  const [connected, setConnected] = useState(false);
  const socket = useRef<Socket | null>(null);

  const [player, setPlayer] = useState<Player | null>(null);
  const [settings, setSettings] = useState<any | null>(null);

  const initSocket = useCallback(() => {
    if (!socket.current) {
      alert("Socket not initialized");
      return;
    }
    socket.current.on("icon", (icon: string) => {
      setPlayer((p) => {
        return { ...p!, icon: icon };
      });
    });

    socket.current.on(
      "init",
      ({ icon, settings }: { icon: string; settings: any }) => {
        console.log("init");
        setPlayer((p) => {
          return { ...p!, icon: icon };
        });
        console.log(settings);
        setSettings(settings);
      }
    );

    socket.current.on("disconnect", () => {
      setConnected(false);
      socket.current = null;
      setPlayer(null);
      setSettings(null);
    });
  }, []);

  const connect = useCallback(
    (code: number, name: string) => {
      return new Promise((resolve) => {
        socket.current = io("https://play.picturedate.live", {
          query: {
            code: code,
            name: name,
            mode: "client",
          },
        });

        socket.current.on("connect", () => {
          console.log("Connected");
          setPlayer({
            name: name,
            icon: ICONS[0],
          });
          setConnected(true);
          socket.current!.off("connect");
          socket.current!.off("connect_error");

          initSocket();
          resolve("Connected");
        });

        socket.current.on("connect_error", (err: any) => {
          socket.current!.off("connect");
          socket.current!.off("connect_error");
          resolve(err);
        });
      });
    },
    [initSocket]
  );

  if (!connected) return <Pregame connect={connect} />;

  return (
    <AppContext.Provider
      value={{ socket: socket.current!, player: player!, settings: settings }}
    >
      <Game />
    </AppContext.Provider>
  );
};

export default App;
