import { motion } from "framer-motion";
import { AppContextType, ICONS } from "./types";
import { useContext } from "react";
import { AppContext } from "./App";

const Character = ({
  horizontal,
  transparent,
}: {
  horizontal?: boolean;
  transparent?: boolean;
}) => {
  const { player, socket } = useContext(AppContext) as AppContextType;
  const { name, icon } = player;

  const img = isNaN(parseInt(icon)) ? icon : ICONS[parseInt(icon)];
  const isCustom = isNaN(parseInt(icon));

  return (
    <motion.div
      className={`${
        transparent ? "" : "bg-sky-400 "
      }m-1.5 rounded text-xl font-medium cursor-pointer text-black text-center flex ${
        horizontal ? "px-4 py-1" : "flex-col max-w-36 px-4 py-2.5"
      } items-center select-none`}
      // animate={{ translateY: 0 }}
      // initial={{ translateY: "100%" }}
      // transition={{ duration: 0.5, ease: "easeInOut" }}
    >
      {isCustom ? (
        <div
          className={`${
            horizontal ? "h-16" : "h-24"
          } aspect-[3/4] mb-2 mt-1 rounded-full bg-red-400 relative overflow-hidden outline-2 outline-black outline-none outline-offset-0`}
        >
          <div
            className={`${
              horizontal
                ? "h-32 w-32 -left-[2.5rem] -top-8"
                : "h-48 w-48 -left-[3.75rem] -top-12"
            } absolute`}
          >
            <img
              src={img}
              alt="Icon"
              className="object-contain h-full w-full flip"
            />
          </div>
          <div className="absolute top-0 left-0 w-full h-full"></div>
        </div>
      ) : (
        <img
          src={img}
          alt="Icon"
          className={`object-contain ${
            horizontal ? "h-16 w-16" : "h-24 w-24"
          } mb-2 mt-1`}
        />
      )}

      <div
        className={`max-w-full text-ellipsis overflow-hidden leading-6 ${
          horizontal ? "ml-3 text-nowrap" : ""
        }`}
      >
        {name}
      </div>
    </motion.div>
  );
};

export default Character;
