import React, { useContext } from "react";
import { AppContext } from "./App";
import { AppContextType } from "./types";
import Character from "./Character";

const InfoBar = () => {
  const { player } = useContext(AppContext) as AppContextType;
  return (
    <div className="w-full p-2">
      <div className="w-full flex justify-between bg-sky-400 rounded items-center">
        <Character horizontal={true} transparent={true} />

        <div className="flex flex-col justify-center mr-2 bg-sky-400 p-2 rounded-xl">
          <div className="text-2xl font-bold text-nowrap">0 pts</div>
          <div className="text-2xl font-bold">#1</div>
        </div>
      </div>
    </div>
  );
};

export default InfoBar;
