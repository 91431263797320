import { useContext, useEffect, useState } from "react";
import { AppContextType, GameState } from "./types";
import Lobby from "./Lobby";
import { AnimatePresence } from "framer-motion";
import { AppContext } from "./App";
import MainScreen from "./MainScreen";

/* 
The player "Green Arrow" has joined a game called PictureDate, where each round players guess the date a photo was taken on. (not historic ones, family/friends/couple ones)
Your task is to make a catchy phrase, between 2-6 words that includes part of or all of the nickname of the player, related to the game, that would show to the player when they join the game. The phrase needs to indicate that the player has successfully joined the game, and it should encourage the player to get ready to play. Make it funny.
*/

/*


*/

const Game = () => {
  const { socket } = useContext(AppContext) as AppContextType;
  const [state, setState] = useState<GameState>("lobby");

  useEffect(() => {
    socket.on("state", (state: GameState) => {
      setState(state);
    });

    return () => {
      socket.off("state");
    };
  }, [socket]);

  return (
    <main className="w-full h-full bg-[#011524] flex flex-col items-center justify-center text-center">
      <AnimatePresence>
        {state === "lobby" ? (
          <Lobby key="lobby" />
        ) : (
          <MainScreen key="mainscreen" state={state} />
        )}
      </AnimatePresence>
    </main>
  );
};

export default Game;
