import { Socket } from "socket.io-client";

export type Player = {
  name: string;
  icon: string;
};

export type GameState =
  | "lobby"
  | "starting"
  | "question"
  | "postquestion"
  | "reveal"
  | "end";

export type AppContextType = { socket: Socket; player: Player; settings?: any };

export const ICONS = [
  "https://cdn-icons-png.flaticon.com/512/3969/3969799.png",
  "https://cdn-icons-png.flaticon.com/512/3969/3969805.png",
  "https://cdn-icons-png.flaticon.com/512/3969/3969791.png",
  "https://cdn-icons-png.flaticon.com/512/3969/3969801.png",
  "https://cdn-icons-png.flaticon.com/512/3969/3969803.png",
  "https://cdn-icons-png.flaticon.com/512/3969/3969722.png",
  "https://cdn-icons-png.flaticon.com/512/3969/3969778.png",
  "https://cdn-icons-png.flaticon.com/512/3969/3969727.png",
  "https://cdn-icons-png.flaticon.com/512/3969/3969785.png",
  "https://cdn-icons-png.flaticon.com/512/3969/3969755.png",
  "https://cdn-icons-png.flaticon.com/512/3969/3969733.png",
  "https://cdn-icons-png.flaticon.com/512/3969/3969793.png",
  "https://cdn-icons-png.flaticon.com/512/3969/3969792.png",
  "https://cdn-icons-png.flaticon.com/512/3969/3969784.png",
  "https://cdn-icons-png.flaticon.com/512/3969/3969789.png",
  "https://cdn-icons-png.flaticon.com/512/3969/3969735.png",
  "https://cdn-icons-png.flaticon.com/512/3969/3969800.png",
  "https://cdn-icons-png.flaticon.com/512/3969/3969798.png",
  "https://cdn-icons-png.flaticon.com/512/3969/3969802.png",
  "https://cdn-icons-png.flaticon.com/512/3969/3969730.png",
  "https://cdn-icons-png.flaticon.com/512/3969/3969747.png",
  "https://cdn-icons-png.flaticon.com/512/3969/3969774.png",
  "https://cdn-icons-png.flaticon.com/512/3969/3969804.png",
  "https://cdn-icons-png.flaticon.com/512/3969/3969741.png",
  "https://cdn-icons-png.flaticon.com/512/3969/3969794.png",
  "https://cdn-icons-png.flaticon.com/512/3969/3969743.png",
  "https://cdn-icons-png.flaticon.com/512/3969/3969796.png",
  "https://cdn-icons-png.flaticon.com/512/3969/3969775.png",
  "https://cdn-icons-png.flaticon.com/512/3969/3969776.png",
  "https://cdn-icons-png.flaticon.com/512/3969/3969772.png",
  "https://cdn-icons-png.flaticon.com/512/3969/3969782.png",
  "https://cdn-icons-png.flaticon.com/512/3969/3969779.png",
  "https://cdn-icons-png.flaticon.com/512/3969/3969788.png",
  "https://cdn-icons-png.flaticon.com/512/3969/3969780.png",
  "https://cdn-icons-png.flaticon.com/512/3969/3969795.png",
  "https://cdn-icons-png.flaticon.com/512/3969/3969783.png",
  "https://cdn-icons-png.flaticon.com/512/3969/3969724.png",
  "https://cdn-icons-png.flaticon.com/512/3969/3969790.png",
  "https://cdn-icons-png.flaticon.com/512/3969/3969766.png",
  "https://cdn-icons-png.flaticon.com/512/3969/3969769.png",
  "https://cdn-icons-png.flaticon.com/512/3969/3969750.png",
  "https://cdn-icons-png.flaticon.com/512/3969/3969786.png",
  "https://cdn-icons-png.flaticon.com/512/3969/3969787.png",
  "https://cdn-icons-png.flaticon.com/512/3969/3969777.png",
  "https://cdn-icons-png.flaticon.com/512/3969/3969753.png",
  "https://cdn-icons-png.flaticon.com/512/3969/3969797.png",
  "https://cdn-icons-png.flaticon.com/512/3969/3969758.png",
  "https://cdn-icons-png.flaticon.com/512/3969/3969781.png",
  "https://cdn-icons-png.flaticon.com/512/3969/3969761.png",
];
