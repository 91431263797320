import { useContext, useEffect, useState } from "react";
import { AppContextType } from "./types";
import Character from "./Character";
import { AppContext } from "./App";
import CustomizeCharacter from "./CustomizeCharacter";
import { AnimatePresence, motion } from "framer-motion";

const Lobby = () => {
  const { player, socket } = useContext(AppContext) as AppContextType;

  const [phrase, setPhrase] = useState<string | null>(null);
  const [isCustomizing, setIsCustomizing] = useState(false);

  useEffect(() => {
    socket.on("phrase", (phrase: string) => {
      setPhrase(phrase);
    });

    return () => {
      socket.off("phrase");
    };
  }, [socket]);
  return (
    <motion.div
      className="fixed top-0 left-0 w-full h-full flex flex-col items-center justify-center px-6"
      exit={{ opacity: 0 }}
    >
      {phrase ? (
        <h1 className="text-sky-400 font-bold text-3xl px-2 mb-3">{phrase}</h1>
      ) : (
        <>
          <div className="bg-sky-400/40 h-[28px] w-full mx-2 mb-2 rounded-lg animate-skeleton"></div>
          <div className="bg-sky-400/40 h-[28px] w-3/4 mx-2 mb-3 rounded-lg animate-skeleton"></div>
        </>
      )}

      <h2 className="text-white text-xl mb-1">
        Customize your character and have fun in the lobby before the game
        begins!
      </h2>
      {player && (
        <div onClick={() => setIsCustomizing(true)}>
          <Character />
        </div>
      )}
      <AnimatePresence>
        {isCustomizing && (
          <CustomizeCharacter exit={() => setIsCustomizing(false)} />
        )}
      </AnimatePresence>
    </motion.div>
  );
};

export default Lobby;
